import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import { faAngleLeft, faEye, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaSpinner } from "react-icons/fa";
import { navigate } from "gatsby";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import countryList from "react-select-country-list";
import { Helmet } from "react-helmet";
import PasswordStrengthBar from "react-password-strength-bar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Rating from "react-rating";
import Layout from "../components/Layout";
import { businessType, NotificationError } from "../components/Helper";

const eye = <FontAwesomeIcon icon={faEye} />;
const MerchantRegisterPage = ({ location }) => {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  const inputEl = useRef(null);
  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [BussnesTag, setBussnesTag] = useState([]);

  const [CountryValue, setCountryValue] = useState();
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountryValue(value);
  };

  useEffect(() => {
    // Update the document title using the browser API
    let BusinessNamee = document.querySelector("#BusinessName")?.value;

    if (location?.state?.name !== "") {
      document.getElementById("TimeOpenMonday").disabled = true;
      document.getElementById("TimeCloseMonday").disabled = true;
      document.getElementById("TimeOpenTuesday").disabled = true;
      document.getElementById("TimeCloseTuesday").disabled = true;
      document.getElementById("TimeOpenWednesday").disabled = true;
      document.getElementById("TimeCloseWednesday").disabled = true;
      document.getElementById("TimeOpenThursday").disabled = true;
      document.getElementById("TimeCloseThursday").disabled = true;
      document.getElementById("TimeOpenFriday").disabled = true;
      document.getElementById("TimeCloseFriday").disabled = true;
      document.getElementById("TimeOpenSaturday").disabled = true;
      document.getElementById("TimeCloseSaturday").disabled = true;
      document.getElementById("TimeOpenSunday").disabled = true;
      document.getElementById("TimeCloseSunday").disabled = true;
    }
  });

  useLayoutEffect(() => {
    if (location?.state?.NextStep) {
      showDiv();
    }

    var type = location?.state?.type;
    if (type) {
      var a = businessType.find((x) => x.Name === type);
      setBussnesTag([]);
      setTimeout(() => {
        setBussnesTag(a?.tagy ?? []);
      }, 1);
    }

    var country = options.find((x) => x.label === location?.state?.country);
    setCountryValue(country);
  }, []);

  const [formState, setFormState] = useState({
    BusinessName: location?.state?.name,
    isBranch: location?.state?.isBranch,
    BranchName: location?.state?.Branch ?? "",
    BusinessType: location?.state?.type,
    Address1: location?.state?.address,
    Category: location?.state?.type,
    City: location?.state?.city,
    Region: location?.state?.region,
    Tags: location?.state?.tagy,
    Price: location?.state?.price,
    Rate: location?.state?.rate,
    From: location?.state?.open,
    To: location?.state?.close,
    Zip: location?.state?.zipcode,
    Country: location?.state?.country,
    Url: location?.state?.url ? location?.state?.url : "not available",
    Phone: location?.state?.phone,
    Review: location?.state?.reviews,
    DayOpenMonday: location?.state?.dayOpenMonday,
    TimeOpenMonday: getTwentyFourHourTime(location?.state?.timeOpenMonday),
    TimeCloseMonday: getTwentyFourHourTime(location?.state?.timeCloseMonday),
    DayOpenTuesday: location?.state?.dayOpenTuesday,
    TimeOpenTuesday: getTwentyFourHourTime(location?.state?.timeOpenTuesday),
    TimeCloseTuesday: getTwentyFourHourTime(location?.state?.timeCloseTuesday),
    DayOpenWednesday: location?.state?.dayOpenWednesday,
    TimeOpenWednesday: getTwentyFourHourTime(
      location?.state?.timeOpenWednesday
    ),
    TimeCloseWednesday: getTwentyFourHourTime(
      location?.state?.timeCloseWednesday
    ),
    DayOpenThursday: location?.state?.dayOpenThursday,
    TimeOpenThursday: getTwentyFourHourTime(location?.state?.timeOpenThursday),
    TimeCloseThursday: getTwentyFourHourTime(
      location?.state?.timeCloseThursday
    ),
    DayOpenFriday: location?.state?.dayOpenFriday,
    TimeOpenFriday: getTwentyFourHourTime(location?.state?.timeOpenFriday),
    TimeCloseFriday: getTwentyFourHourTime(location?.state?.timeCloseFriday),
    DayOpenSaturday: location?.state?.dayOpenSaturday,
    TimeOpenSaturday: getTwentyFourHourTime(location?.state?.timeOpenSaturday),
    TimeCloseSaturday: getTwentyFourHourTime(
      location?.state?.timeCloseSaturday
    ),
    DayOpenSunday: location?.state?.dayOpenSunday,
    TimeOpenSunday: getTwentyFourHourTime(location?.state?.timeOpenSunday),
    TimeCloseSunday: getTwentyFourHourTime(location?.state?.timeCloseSunday),
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
  });

  function getTwentyFourHourTime(amPmString) {
    var d = new Date("1/1/2013 " + amPmString);
    return (
      (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
      ":" +
      (d.getMinutes() == 0 ? "00" : d.getMinutes())
    );
  }

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  function showDivbehind() {
    document.querySelector(".showmydiv").style.display = "none";
    document.querySelector(".showmydivtitle").style.display = "none";
    document.querySelector(".showbutton").style.display = "none";
    document.querySelector(".displaydivtitle").style.display = "block";
    document.querySelector(".displaydiv").style.display = "block";
  }
  function showDiv() {
    document.querySelector(".showmydiv").style.display = "block";
    document.querySelector(".showmydivtitle").style.display = "block";
    document.querySelector(".showbutton").style.display = "block";
    document.querySelector(".displaydivtitle").style.display = "none";
    document.querySelector(".displaydiv").style.display = "none";
  }

  function GetValueByID(str) {
    var a = document.getElementById(str);
    if (a) return a.value;
    else {
      return "";
    }
  }

  function CheckForm(id = "", message = "") {
    var a = document.getElementById(id);
    if (a === null || a?.value === "") {
      NotificationError({ message: message });
      return true;
    }
  }

  function CheckFormByValue(value, message = "") {
    if (value === null) {
      NotificationError({ message: message });
      return true;
    }
  }

  const handleSubmit = (e) => {
    var isError = CheckFormByValue(
      formState.BusinessType,
      "business Type is required"
    );
    isError = CheckForm("BusinessName", "business Name is required");
    isError = CheckForm("Address1", "Address is required");
    isError = CheckForm("City", "City is required");
    isError = CheckFormByValue(CountryValue?.label, "Country is required");

    if (!isError) {
      setLoading(true);
      const form = e.target;
      let PhoneNumber = document.querySelector("#PhoneNumber")?.value;
      let phoneCode = document.querySelector(".react-tel-input input")?.value;
      let Password = document.querySelector("#Password")?.value;

      let ThePrice = document.querySelector("#Price")?.value;
      if (ThePrice === "$") ThePrice = 1;
      if (ThePrice === "$$") ThePrice = 2;
      if (ThePrice === "$$$") ThePrice = 3;
      if (ThePrice === "$$$$") ThePrice = 4;
      if (ThePrice === "Not available") ThePrice = 0;
      if (ThePrice === "false") ThePrice = 0;
      let TheRate = document.querySelector("#Rate")?.value;
      if (TheRate === "" || TheRate === "undefined") TheRate = 0;
      let TimeOpenMonday = document.getElementById("TimeOpenMonday").value;
      if (TimeOpenMonday === "") TimeOpenMonday = "Not available";
      let TimeCloseMonday = document.getElementById("TimeCloseMonday").value;
      if (TimeCloseMonday === "") TimeCloseMonday = "Not available";

      let TimeOpenTuesday = document.getElementById("TimeOpenTuesday").value;
      if (TimeOpenTuesday === "") TimeOpenTuesday = "Not available";
      let TimeCloseTuesday = document.getElementById("TimeCloseTuesday").value;
      if (TimeCloseTuesday === "") TimeCloseTuesday = "Not available";

      let TimeOpenWednesday =
        document.getElementById("TimeOpenWednesday").value;
      if (TimeOpenWednesday === "") TimeOpenWednesday = "Not available";
      let TimeCloseWednesday =
        document.getElementById("TimeCloseWednesday").value;
      if (TimeCloseWednesday === "") TimeCloseWednesday = "Not available";

      let TimeOpenThursday = document.getElementById("TimeOpenThursday").value;
      if (TimeOpenThursday === "") TimeOpenThursday = "Not available";
      let TimeCloseThursday =
        document.getElementById("TimeCloseThursday").value;
      if (TimeCloseThursday === "") TimeCloseThursday = "Not available";

      let TimeOpenFriday = document.getElementById("TimeOpenFriday").value;
      if (TimeOpenFriday === "") TimeOpenFriday = "Not available";
      let TimeCloseFriday = document.getElementById("TimeCloseFriday").value;
      if (TimeCloseFriday === "") TimeCloseFriday = "Not available";

      let TimeOpenSaturday = document.getElementById("TimeOpenSaturday").value;
      if (TimeOpenSaturday === "") TimeOpenSaturday = "Not available";
      let TimeCloseSaturday =
        document.getElementById("TimeCloseSaturday").value;
      if (TimeCloseSaturday === "") TimeCloseSaturday = "Not available";

      let TimeOpenSunday = document.getElementById("TimeOpenSunday").value;
      if (TimeOpenSunday === "") TimeOpenSunday = "Not available";
      let TimeCloseSunday = document.getElementById("TimeCloseSunday").value;
      if (TimeCloseSunday === "") TimeCloseSunday = "Not available";

      var tages = selected.map((e) => ({ Name: e }));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        FirstName: document.getElementById("FirstName").value,
        LastName: document.getElementById("LastName").value,
        Email: document.getElementById("Email").value,
        PhoneNumber: phoneCode + PhoneNumber,
        Password: Password,
        Business: {
          Name: document.getElementById("BusinessName").value,
          Website: formState.Url,
          Address: {
            Branch:
              document.getElementById("BranchName")?.value ??
              document.getElementById("BusinessName")?.value,
            AddressText: document.getElementById("Address1").value,
            City: document.getElementById("City").value,
            ZipCode: document.getElementById("Zip").value,
            Country: CountryValue?.label,
          },
          Category: { Name: formState.BusinessType },
          Tags: tages,
          BusinessHour: [
            {
              DayName: "Monday",
              OpenTime: TimeOpenMonday,
              CloseTime: TimeCloseMonday,
            },
            {
              DayName: "Thusday",
              OpenTime: TimeOpenTuesday,
              CloseTime: TimeCloseTuesday,
            },
            {
              DayName: "Wednesday",
              OpenTime: TimeOpenWednesday,
              CloseTime: TimeCloseWednesday,
            },
            {
              DayName: "Thursday",
              OpenTime: TimeOpenThursday,
              CloseTime: TimeCloseThursday,
            },
            {
              DayName: "Friday",
              OpenTime: TimeOpenFriday,
              CloseTime: TimeCloseFriday,
            },
            {
              DayName: "Saturday",
              OpenTime: TimeOpenSaturday,
              CloseTime: TimeCloseSaturday,
            },
            {
              DayName: "Sunday",
              OpenTime: TimeOpenSunday,
              CloseTime: TimeCloseSunday,
            },
          ],
          StarRating: TheRate,
          PriceRange: ThePrice,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/api/Account/RegisterMerchant`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            navigate(
              "/merchant-confirmation?phonenumber=" +
                phoneCode +
                PhoneNumber +
                ""
            );
          }

          return response?.text() ?? "";
        })
        .then((result) => {
          var json = JSON.parse(result);
          setLoading(false);
          if (json.Message === "The request is invalid.") {
            document.querySelector(".merchnaterror").style.display = "block";
          } else {
            document.querySelector(".merchnaterror").style.display = "none";
          }
          if (
            json.Message ===
            "User Error: " + phoneCode + PhoneNumber + " is already registered."
          ) {
            document.querySelector(".phonemerchant").style.display = "block";
          } else {
            document.querySelector(".phonemerchant").style.display = "none";
          }

          console.log(result);
        })
        .catch((error) => {
          setLoading(false);
          alert("error" + error);
        });
    }
    e.preventDefault();
  };

  return (
    <Layout>
      <Helmet></Helmet>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <div className="row">
                <div
                  className="col-xl-2 text-start showbutton"
                  onClick={() => {
                    showDivbehind();
                  }}
                >
                  <button type="button" className="btn btn-link">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      data-prefix="far"
                      className="mt-3 fa-lg far text-dark"
                    />
                  </button>
                </div>
                <div className="col-xl-9">
                  <h1 className="fs-48 fw-bold mb-5 showmydivtitle">
                    Login details
                  </h1>
                </div>
              </div>

              <h1 className="fs-48 fw-bold mb-5 displaydivtitle">
                Register as new Merchant{" "}
              </h1>
            </div>
            <div className="col-xl-5 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <form
                onSubmit={handleSubmit}
                name="merchant-register-data"
                method="post"
                data-netlify-recaptcha="true"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                autocomplete="off"
                id="address-form"
                className="text-start"
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="merchant-register-data"
                />
                <div className="displaydiv">
                  <Row className="mb-3 text-start">
                    <Form.Group className="mb-3 text-start">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Business Type *
                      </Form.Label>
                      <Select
                        value={{
                          value: formState.BusinessType,
                          label: formState.BusinessType,
                        }}
                        onChange={(e) => {
                          var a = businessType.find((x) => x.Name === e.value);
                          setBussnesTag([]);
                          setTimeout(() => {
                            setSelected([]);
                            setBussnesTag(a?.tagy ?? []);
                            setFormState({
                              ...formState,
                              BusinessType: e.value,
                            });
                          }, 1);
                        }}
                        options={businessType?.map((e) => {
                          return { value: e.Name, label: e.Name };
                        })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGridBusiness" className="mb-3">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Business Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="BusinessName"
                        id="BusinessName"
                        onChange={handleChange}
                        value={formState.BusinessName}
                      />
                    </Form.Group>
                    {formState?.isBranch && (
                      <Form.Group controlId="formGridBusiness" className="mb-3">
                        <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                          Branch Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="BranchName"
                          id="BranchName"
                          onChange={handleChange}
                          value={formState.BranchName}
                        />
                      </Form.Group>
                    )}
                  </Row>
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formGridAddress1"
                  >
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Address *
                    </Form.Label>
                    <input
                      onChange={handleChange}
                      value={formState.Address1}
                      name="Address1"
                      id="Address1"
                      /* required */
                      className="form-control"
                    />
                  </Form.Group>
                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        State/City *
                      </Form.Label>
                      <Form.Control
                        ref={inputEl}
                        name="City"
                        onChange={handleChange}
                        value={formState.City}
                        id="City"
                        /* required */
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Postal Code
                      </Form.Label>
                      <Form.Control
                        name="Zip"
                        onChange={handleChange}
                        value={formState.Zip}
                        id="Zip"
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Country *
                      </Form.Label>
                      <Select
                        options={options}
                        value={CountryValue}
                        onChange={changeHandler}
                      />
                      {/*  <Form.Control
                        name="Country"
                        onChange={handleChange}
                        value={formState.Country}
                        id="Country"
                        required 
                      /> */}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="formGridZip"
                      style={{ display: "none" }}
                    >
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Category
                      </Form.Label>
                      <Form.Control
                        name="Category"
                        onChange={handleChange}
                        value={formState.Category}
                        id="Category"
                        /* required */
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Tags
                    </Form.Label>
                    <Multiselect
                      selectionLimit={5}
                      isObject={false}
                      className="px-0"
                      showArrow={true}
                      selectedValues={selected}
                      onSelect={(e) => {
                        setSelected(e);
                      }}
                      options={BussnesTag?.map((e) => {
                        return e;
                      })}
                    />
                  </Form.Group>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Monday</b> : Open
                      </Form.Label>

                      <Form.Control
                        name="TimeOpenMonday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenMonday}
                        id="TimeOpenMonday"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseMonday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseMonday}
                        id="TimeCloseMonday"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Tuesday</b> : Open
                      </Form.Label>
                      <Form.Control
                        name="TimeOpenTuesday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenTuesday}
                        id="TimeOpenTuesday"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseTuesday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseTuesday}
                        id="TimeCloseTuesday"
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Wednesday</b> : Open
                      </Form.Label>
                      <Form.Control
                        name="TimeOpenWednesday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenWednesday}
                        id="TimeOpenWednesday"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseWednesday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseWednesday}
                        id="TimeCloseWednesday"
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Thursday</b> : Open
                      </Form.Label>
                      <Form.Control
                        name="TimeOpenThursday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenThursday}
                        id="TimeOpenThursday"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseThursday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseThursday}
                        id="TimeCloseThursday"
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Friday</b> : Open
                      </Form.Label>
                      <Form.Control
                        name="TimeOpenFriday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenFriday}
                        id="TimeOpenFriday"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseFriday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseFriday}
                        id="TimeCloseFriday"
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Saturday</b> : Open
                      </Form.Label>
                      <Form.Control
                        name="TimeOpenSaturday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenSaturday}
                        id="TimeOpenSaturday"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseSaturday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseSaturday}
                        id="TimeCloseSaturday"
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 text-start">
                    <Form.Group as={Col} controlId="formGridCountry">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        <b>Sunday</b> : Open
                      </Form.Label>
                      <Form.Control
                        name="TimeOpenSunday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeOpenSunday}
                        id="TimeOpenSunday"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Close
                      </Form.Label>
                      <Form.Control
                        name="TimeCloseSunday"
                        type="time"
                        onChange={handleChange}
                        value={formState.TimeCloseSunday}
                        id="TimeCloseSunday"
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight w-100">
                      Rating
                    </Form.Label>
                    <input
                      type="text"
                      style={{ display: "none" }}
                      id="Rate"
                      name="Rate"
                      value={formState.Rate}
                    />
                    <div id="rate-disbled">
                      <Rating
                        initialRating={formState.Rate}
                        name="Rate"
                        id="Rate"
                        readonly="true"
                        emptySymbol={
                          <FontAwesomeIcon
                            icon={faStar}
                            data-prefix="far"
                            className="me-2 fa-lg far text-secondary"
                          />
                        }
                        fullSymbol={
                          <FontAwesomeIcon
                            icon={faStar}
                            className="me-2 fa-lg text-warning"
                          />
                        }
                      />

                      <Form.Label className="bogo-par fs-13 text-start fw-airlight ">
                        {formState.Review} Google reviews
                      </Form.Label>
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="formGridPhone"
                    className="mb-3 text-start"
                  >
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight"></Form.Label>
                    <Form.Control
                      name="Price"
                      type="text"
                      onChange={handleChange}
                      value={
                        (+formState.Price === 1 && "$") ||
                        (+formState.Price === 2 && "$$") ||
                        (+formState.Price === 3 && "$$$") ||
                        (+formState.Price === 4 && "$$$$") ||
                        ((formState.Price === "undefined" ||
                          formState.Price === "" ||
                          !formState?.Price) &&
                          "Not available")
                      }
                      id="Price"
                      required
                      disabled
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formGridPhone"
                    className="mb-3 text-start"
                  >
                    {/* <Recaptcha
                    ref={recaptchaRef}
                    sitekey="6LeUanMcAAAAAAk3U8ieboKe0uqx1LNFb8i4QvUi"
                    size="normal"
                    id="recaptcha-google"
                    onChange={() => setButtonDisabled(false)}
                  />*/}
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="w-100 fs-14 fw-bold h-52 show"
                    onClick={() => {
                      showDiv();
                    }}
                  >
                    Submit Details
                  </Button>
                </div>

                <div className="showmydiv">
                  <Form.Group controlId="formGridfirst" className="mb-4">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      First name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="FirstName"
                      onChange={handleChange}
                      value={formState.FirstName}
                      id="FirstName"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridLast" className="mb-4">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Last name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="LastName"
                      onChange={handleChange}
                      value={formState.LastName}
                      id="LastName"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formGridEmail" className="mb-4">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      E-mail address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="Email"
                      onChange={handleChange}
                      value={formState.Email}
                      id="Email"
                      required
                    />
                  </Form.Group>
                  <p
                    className="merchnaterror"
                    style={{ display: "none", fontSize: "11px", color: "red" }}
                  >
                    Email is already taken
                  </p>

                  <Form.Group
                    controlId="formGridPassword"
                    className="pass-wrapper"
                  >
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Create password
                    </Form.Label>
                    <Form.Control
                      id="Password"
                      name="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={passwordShown ? "text" : "password"}
                    />

                    <i
                      role="button"
                      onClick={togglePasswordVisiblity}
                      aria-hidden="true"
                    >
                      {eye}
                    </i>
                    <div className="row">
                      <div className="col mt-1">
                        <Form.Text className="bogo-par fs-13 fw-airlight">
                          6-12 characters
                        </Form.Text>
                      </div>
                      <div className="col">
                        <PasswordStrengthBar
                          scoreWordStyle={true}
                          barColors={false}
                          password={password}
                          shortScoreWord="Short"
                          className="text-uppercase"
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="formGridPassword"
                    className="pass-wrapper mb-0"
                  >
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Repeat password
                    </Form.Label>
                    <Form.Control
                      name="Password"
                      onBlur={(e) => {
                        var thisValue = e.target.value;
                        var FirstValue =
                          document.getElementById("Password").value;

                        if (thisValue === FirstValue) {
                          setPasswordRepeat(e.target.value);
                          document.querySelector(
                            ".merchantpassword"
                          ).style.display = "none";
                        } else {
                          document.querySelector(
                            ".merchantpassword"
                          ).style.display = "block";
                        }
                      }}
                      type={passwordShown ? "text" : "password"}
                    />
                    <i
                      role="button"
                      onClick={togglePasswordVisiblity}
                      aria-hidden="true"
                    >
                      {eye}
                    </i>
                    <PasswordStrengthBar
                      scoreWordStyle={true}
                      barColors={false}
                      password={passwordRepeat}
                      shortScoreWord="Short"
                      className="text-uppercase"
                    />
                  </Form.Group>
                  <p
                    className="merchantpassword"
                    style={{ display: "none", fontSize: "11px", color: "red" }}
                  >
                    the password doesnt match
                  </p>

                  <FormLabel className="bogo-par fs-13 fw-airlight">
                    Mobile number
                  </FormLabel>
                  <InputGroup className="mb-4">
                    <PhoneInput country="ae" enableAreaCodes={true} />
                    <FormControl
                      type="tel"
                      aria-label="Text input with dropdown button"
                      id="PhoneNumber"
                      required
                    />
                  </InputGroup>
                  <p
                    className="phonemerchant"
                    style={{ display: "none", fontSize: "11px", color: "red" }}
                  >
                    Number Phone is already taken
                  </p>

                  {!isLoading && (
                    <Button
                      variant="primary"
                      className="w-100 fs-14 fw-bold h-52"
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                  {isLoading && (
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-100 fs-14 fw-bold h-52"
                    >
                      <FaSpinner
                        icon="spinner"
                        className="spinner"
                        style={{ color: "#fff", fontSize: "28px" }}
                      />
                      Sending...
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
};
export default MerchantRegisterPage;
